<template>
  <div class="v-assetsEditView">
    <div v-if="asset">
      <h1
        class="tw-mb-10 tw-text-xl tw-font-bold tw-text-grey34 dark:tw-text-greyc9"
      >
        {{ $t('views.assets.newAssetToSystem') }}
      </h1>
      <div>
        <UITabs :tabs="tabs" :active="activeTab" @setActive="changeTab" />
        <div v-if="activeTab === 'generalMetrics'">
          <GeneralMetricsTab :lines="ticketLines" />
        </div>
        <div v-if="activeTab === 'criticality'">
          <CriticalityTab :tabs="tabs" @setActive="changeTab" />
        </div>
        <div v-if="activeTab === 'residualRisks'">
          <ResidualRisksTab :asset-id="id" />
        </div>
        <div v-if="activeTab === 'reports'"><ReportsTab :asset-id="id" /></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import DashboardLayout from '@/components/layouts/DashboardLayout.vue'
import RiskManagement from '@/components/views/dashboard/riskManagement/RiskManagement.vue'
import UITabs from '@/components/UI/UITabs.vue'
import EventsManagement from '@/components/views/dashboard/eventsManagement/EventsManagement.vue'
import type { ITab } from '@/types/TabsTypes'
import GeneralMetricsTab from '@/components/views/assets/GeneralMetricsTab.vue'
import CriticalityTab from '@/components/views/assets/CriticalityTab.vue'
import ResidualRisksTab from '@/components/views/assets/ResidualRisksTab.vue'
import ReportsTab from '@/components/views/assets/ReportsTab.vue'
import { assetsService, userService } from '@/main'
import type { Nullable } from '@/types/Nullable'
import type { IAsset } from '@/types/AssetsTypes'
import type { IUser } from '@/types/UserTypes'
import { IGlossaryLineTicket } from '@/types/GlossariesTypes'

@Component({
  components: {
    CriticalityTab,
    GeneralMetricsTab,
    EventsManagement,
    UITabs,
    RiskManagement,
    DashboardLayout,
    ResidualRisksTab,
    ReportsTab,
  },
})
export default class AssetsEditView extends Vue {
  public get id(): Nullable<number> {
    return this.$route?.params?.id ? Number(this.$route.params.id) : null
  }

  public get tabQuery(): Nullable<string> {
    return this.$route?.query?.tab ?? null
  }

  public get asset(): Nullable<IAsset> {
    return assetsService.asset
  }

  public get user(): Nullable<IUser> {
    return userService.getUser
  }

  public ticketLines: IGlossaryLineTicket[] = []

  public async allData(): Promise<void> {
    assetsService.clearAssetAndCriticality()
    if (this.id) {
      await assetsService.loadAsset(this.id).then(async () => {
        try {
          if (this.asset && this.asset.id && this.asset?.status_id > 1) {
            await assetsService.loadCriticality(this.asset?.id)
          }
        } catch (e) {
          console.error(e)
        }
      })
    } else {
      await assetsService.loadLastAsset(this.user.id).then(async () => {
        try {
          if (this.asset && this.asset.id && this.asset?.status_id > 1) {
            await assetsService.loadCriticality(this.asset?.id)
          }
        } catch (e) {
          console.error(e)
        }
      })
    }
  }

  public async mounted(): Promise<void> {
    await this.allData().then(() => {
      this.setTab()
    })
  }

  public get tabs(): ITab[] {
    return [
      {
        label: this.$t('views.assets.generalMetrics').toString(),
        value: 'generalMetrics',
        disabled: false,
      },
      {
        label: this.$t('views.assets.criticality').toString(),
        value: 'criticality',
        disabled: this.asset?.status_id < 2,
      },
      {
        label: this.$t('views.assets.residualRisks').toString(),
        value: 'residualRisks',
        disabled: this.asset?.status_id < 3,
      },
      {
        label: this.$t('views.assets.reports').toString(),
        value: 'reports',
        disabled: this.asset?.status_id < 3,
      },
    ]
  }

  public activeTab: ITab['value'] = 'generalMetrics'

  public async loadRisks(): Promise<void> {
    await assetsService.loadAssetRisks(this.asset.id)
  }

  public setTab(): void {
    if (this.tabQuery) {
      const tab = this.tabs.find((t) => t.value === this.tabQuery)
      if (tab) {
        this.changeTab(tab)
      }
    }
  }

  public changeTab(tab: ITab): void {
    if (tab.disabled !== true) {
      this.activeTab = tab.value
      if (tab.value === 'residualRisks') {
        this.loadRisks()
      }
    }
  }
}
</script>
