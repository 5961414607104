<template>
  <div class="c-uiInput tw-text-start" :class="uiClass">
    <div
      :class="[
        { 'is-invalid': isError },
        {
          'focus is-focused withValue tw-select-none': isLabelOnBorder,
        },
        {
          withFocus: isFocused,
        },
        { 'input-group input-group-outline': !labelOnTop },
        inputGroupClass,
      ]"
    >
      <label
        class="form-label tw-pointer-events-none !tw-h-max"
        v-if="label && !placeholder"
        :for="id"
      >
        {{ label }}
        <span
          v-if="isMandatory"
          class="text-danger tw-mx-1 -tw-mt-1 tw-text-[14px]"
          >*</span
        ></label
      >
      <input
        :id="id"
        :name="id"
        :type="type"
        v-bind="$attrs"
        :max="type === 'date' ? '9999-12-31' : null"
        :min="type === 'date' ? '1990-01-01' : null"
        :placeholder="placeholder"
        class="form-control tw-h-[44px]"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target!.value)"
        @change="$emit('change', $event.target!.value)"
        @focus="handleFocus"
        @blur="handleBlur"
      />
      <span
        v-if="isTooltip"
        v-b-tooltip.hover
        :title="tooltipText"
        class="tw-absolute -tw-right-2 tw-top-0 -tw-mt-9 tw-mr-2 tw-cursor-pointer tw-text-gray-400"
      >
        <font-awesome-icon
          icon="icon fa-solid fa-circle-info"
          class="tw-mt-2 tw-text-xl"
        />
      </span>
    </div>
    <slot />
    <span
      class="tw-mt-1 !tw-block tw-rounded-lg tw-bg-red-600 tw-px-2 tw-py-1 tw-text-center tw-text-xs tw-uppercase tw-text-white"
      :class="errorClass"
      role="alert"
      v-if="isError && error.length"
    >
      <strong>{{ error }}</strong>
    </span>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { TranslateResult } from 'vue-i18n'

@Component({
  emits: ['change', 'update:modelValue', 'focusOut'],
})
export default class UIInput extends Vue {
  @Prop({ required: true, default: '' })
  public modelValue!: string

  @Prop({ required: false, default: 'text' })
  public type!: string

  @Prop({ required: false })
  public label!: string | TranslateResult

  @Prop({ required: false, default: false })
  public labelOnTop!: boolean

  @Prop({ required: false })
  public isError!: boolean

  @Prop({ required: false })
  public error!: string

  @Prop({ required: false })
  public uiClass!: string

  @Prop({ required: false })
  public inputGroupClass!: string

  @Prop({ required: false })
  public errorClass!: string

  @Prop({ required: false })
  public id!: string

  @Prop({ required: false, default: true })
  public withFocusEffect!: boolean

  @Prop({ required: false, default: '' })
  public placeholder!: string | TranslateResult

  @Prop({ required: false, default: false })
  public isMandatory!: boolean

  @Prop({ required: false, default: false })
  public labelOnBorder!: boolean

  @Prop({ required: false, default: false })
  public isTooltip!: boolean

  @Prop({ required: false, default: '' })
  public tooltipText!: string

  public isFocused: boolean = false

  public handleFocus(): void {
    this.isFocused = true
  }

  public handleBlur(): void {
    this.isFocused = false
    this.$emit('focusOut')
  }

  public get isSpecialType(): boolean {
    return (
      this.type == 'number' ||
      this.type === 'date' ||
      this.type === 'datetime-local'
    )
  }

  public get isLabelOnBorder(): boolean {
    if (this.isFocused) {
      return true
    }

    if (this.type === 'datetime-local') {
      return true
    }

    if (this.isSpecialType && this.modelValue) {
      return true
    }

    const hasValue = this.modelValue && this.modelValue.length > 0
    const hasValueOrSpecialOrLabelOnBorder = hasValue || this.labelOnBorder
    return (
      this.isFocused ||
      Boolean(this.withFocusEffect && hasValueOrSpecialOrLabelOnBorder)
    )
  }
}
</script>

<style lang="scss" scoped>
.form-label {
  color: var(--header-color);
  z-index: var(--z-10);
}

:deep(.form-control) {
  padding: 10px 12px !important;
  border-radius: 0.375rem !important;
  &:focus {
    border: 1px solid #d81b60 !important;
  }
}
.with-icon input#search {
  padding-right: 35px !important;
}

.with-icon input#password,
.with-icon input#repeatPassword {
  padding-right: 45px !important;
}

.input-group.input-group-outline.is-focused.withValue
  .form-label
  + .form-control {
  border-width: 0;
  border-color: #d2d6da !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #d2d6da, inset -1px 0 #d2d6da, inset 0 -1px #d2d6da;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out !important;
}

.input-group.input-group-outline.is-focused.withFocus
  .form-label
  + .form-control {
  border-width: 1px;
  border-color: #d71b49 !important;
  border-top-color: transparent !important;
  box-shadow: inset 1px 0 #d71b49, inset -1px 0 #d71b49, inset 0 -1px #d71b49;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out !important;
}

.input-group.input-group-outline.is-focused.withValue .form-label {
  border-width: 0;
  color: var(--header-color);
}

.input-group.input-group-outline.is-focused.withFocus .form-label {
  color: #d71b49;
}

.input-group.input-group-outline.is-focused.withValue .form-label:before,
.input-group.input-group-outline.is-focused.withValue .form-label:after {
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out !important;
  border-width: 0;
  opacity: 1;
  border-top-color: #d2d6da;
  box-shadow: inset 0 1px #d2d6da;
}

.input-group.input-group-outline.is-focused.withFocus .form-label:before,
.input-group.input-group-outline.is-focused.withFocus .form-label:after {
  border-width: 1px;
  opacity: 1;
  border-top-color: #d71b49;
  box-shadow: inset 0 1px #d71b49;
  transition: border-color 0.1s ease-in-out, box-shadow 0.1s ease-in-out !important;
}

.c-uiInput input[type='datetime-local'].form-control {
  background-image: none !important;
}
</style>
