<template>
  <div class="c-generalMetricsTab tw-mt-8" v-if="asset">
    <div
      v-if="asset.status_id > 1 && asset.status_id < 3 && !isEditMode"
      class="tw-flex tw-justify-end"
    >
      <UIButton
        type="secondary"
        class="tw-w-[100px]"
        :label="$t('button.edit')"
        @click="isEditMode = true"
      />
    </div>
    <GlossariesAssetForm
      @endForm="setShowBasicMetricForm"
      :lines="lines"
      :disabled="isDisabledForm"
      @reloadForm="$emit('reloadForm')"
    />
    <div
      class="tw-grid tw-grid-cols-1 tw-gap-3 xl:tw-grid-cols-2 xl:tw-gap-6"
      v-if="showBasicMetricForm"
    >
      <UIListbox
        v-model="asset.company_id"
        id="company"
        name="company"
        :label="$t('views.assets.company')"
        input-group-class="tw-mb-3"
        :options="companiesList"
        :isMandatory="true"
        :labelOnBorder="true"
        :placeholder="$t('views.assets.select')"
        :disabled="isDisabledForm"
        :error="baseDataErrors['assetData.company_id']"
        :is-error="
          baseDataErrors['assetData.company_id'] &&
          baseDataErrors['assetData.company_id'].length > 0
        "
        @change="updateCompanyField(asset.company_id)"
      />
      <UIListbox
        v-model="asset.organizational_unit"
        id="unit"
        name="unit"
        :label="$t('views.assets.unit')"
        input-group-class="tw-mb-3"
        :options="unitsList"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="
          asset.company_id === null ||
          companiesUnits.length === 0 ||
          isDisabledForm
        "
        :error="baseDataErrors['assetData.organizational_unit']"
        :is-error="
          baseDataErrors['assetData.organizational_unit'] &&
          baseDataErrors['assetData.organizational_unit'].length > 0
        "
        :placeholder="$t('views.assets.select')"
        @change="
          updateField(false, 'organizational_unit', asset.organizational_unit)
        "
      />
      <UIInput
        v-model="asset.name"
        id="systemOrServer"
        name="systemOrServer"
        :label="$t('views.assets.systemOrServer')"
        input-group-class="tw-mb-3"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :error="baseDataErrors['assetData.name']"
        :is-error="
          baseDataErrors['assetData.name'] &&
          baseDataErrors['assetData.name'].length > 0
        "
        @change="updateField(false, 'name', asset.name)"
      />
      <UIInput
        v-model="asset.short_name"
        id="abbreviatedSystemOrServer"
        name="abbreviatedSystemOrServer"
        :label="$t('views.assets.abbreviatedSystemOrServer')"
        input-group-class="tw-mb-3"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :error="baseDataErrors['assetData.short_name']"
        :is-error="
          baseDataErrors['assetData.short_name'] &&
          baseDataErrors['assetData.short_name'].length > 0
        "
        @change="updateField(false, 'short_name', asset.short_name)"
      />
      <UIInput
        v-model="asset.description"
        id="assetDescription"
        name="assetDescription"
        :label="$t('views.assets.assetDescription')"
        input-group-class="tw-mb-3"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :error="baseDataErrors['assetData.description']"
        :is-error="
          baseDataErrors['assetData.description'] &&
          baseDataErrors['assetData.description'].length > 0
        "
        @change="updateField(false, 'description', asset.description)"
      />
      <UIListbox
        v-model="asset.type"
        id="typeOfAsset"
        name="typeOfAsset"
        :label="$t('views.assets.typeOfAsset')"
        input-group-class="tw-mb-3"
        :options="typeOfAssetOptions"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="baseDataErrors['assetData.type']"
        :is-error="
          baseDataErrors['assetData.type'] &&
          baseDataErrors['assetData.type'].length > 0
        "
        @change="updateField(false, 'type', asset.type)"
      />
      <UIListbox
        v-model="asset.technical_owner"
        id="technicalOwner"
        name="technicalOwner"
        :label="$t('views.assets.technicalOwner')"
        input-group-class="tw-mb-3"
        :options="userList"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="baseDataErrors['assetData.technical_owner']"
        :is-error="
          baseDataErrors['assetData.technical_owner'] &&
          baseDataErrors['assetData.technical_owner'].length > 0
        "
        @change="updateField(false, 'technical_owner', asset.technical_owner)"
      />
      <UIListbox
        v-model="asset.deputy_technical_owner"
        id="substituteTechnicalOwner"
        name="substituteTechnicalOwner"
        :label="$t('views.assets.substituteTechnicalOwner')"
        input-group-class="tw-mb-3"
        :options="userList"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="baseDataErrors['assetData.deputy_technical_owner']"
        :is-error="
          baseDataErrors['assetData.deputy_technical_owner'] &&
          baseDataErrors['assetData.deputy_technical_owner'].length > 0
        "
        @change="
          updateField(
            false,
            'deputy_technical_owner',
            asset.deputy_technical_owner
          )
        "
      />
      <UIListbox
        v-model="asset.business_owner"
        id="businessOwner"
        name="businessOwner"
        :label="$t('views.assets.businessOwner')"
        input-group-class="tw-mb-3"
        :options="userList"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="baseDataErrors['assetData.business_owner']"
        :is-error="
          baseDataErrors['assetData.business_owner'] &&
          baseDataErrors['assetData.business_owner'].length > 0
        "
        @change="updateField(false, 'business_owner', asset.business_owner)"
      />
      <UIListbox
        v-model="asset.deputy_business_owner"
        id="substituteBizOwner"
        name="substituteBizOwner"
        :label="$t('views.assets.substituteBizOwner')"
        input-group-class="tw-mb-3"
        :options="userList"
        :isMandatory="true"
        :labelOnBorder="true"
        :disabled="isDisabledForm"
        :placeholder="$t('views.assets.select')"
        :error="baseDataErrors['assetData.deputy_business_owner']"
        :is-error="
          baseDataErrors['assetData.deputy_business_owner'] &&
          baseDataErrors['assetData.deputy_business_owner'].length > 0
        "
        @change="
          updateField(
            false,
            'deputy_business_owner',
            asset.deputy_business_owner
          )
        "
      />
    </div>
    <div
      class="tw-mt-4 tw-flex tw-items-center tw-gap-2"
      v-if="showBasicMetricForm"
    >
      <p class="tw-mb-0 tw-mr-4 tw-text-sm tw-text-grey7b">
        {{ $t('views.assets.materialAsset') }}:
      </p>
      <UIRadio
        v-model="asset.additional_data.is_tangible"
        @update:modelValue="handleUpdateRadio"
        name="materialAssetYes"
        id="materialAssetYes"
        :disabled="isDisabledForm"
        :value="1"
        :label="$t('common.yes')"
      />
      <UIRadio
        v-model="asset.additional_data.is_tangible"
        @update:modelValue="handleUpdateRadio"
        name="materialAssetNo"
        id="materialAssetNo"
        :disabled="isDisabledForm"
        :value="0"
        :label="$t('common.no')"
      />
      <span
        class="badge badge-danger d-block tw-mt-1 !tw-bg-red-600"
        role="alert"
        v-if="
          assetAdditionalDataErrors['assetAdditionalData.is_tangible'] &&
          assetAdditionalDataErrors['assetAdditionalData.is_tangible'].length >
            0
        "
      >
        <strong class="tw-text-wrap tw-leading-5">{{
          assetAdditionalDataErrors['assetAdditionalData.is_tangible']
        }}</strong>
      </span>
    </div>
    <div
      v-if="asset.additional_data.is_tangible === 1 && showBasicMetricForm"
      class="tw-mb-14 tw-mt-14"
    >
      <div class="tw-mb-12 xl:tw-mb-14">
        <h2 class="tw-mb-8 tw-mr-3 tw-text-xl tw-font-bold tw-text-grey34">
          {{ $t('views.assets.assetInfo') }}:
        </h2>
        <div
          class="tw-grid tw-grid-cols-1 tw-gap-3 xl:tw-grid-cols-2 xl:tw-gap-6"
        >
          <UIInput
            v-model="asset.additional_data.purchase_value"
            id="purchaseInPLN"
            name="purchaseInPLN"
            :label="$t('views.assets.purchaseInPLN')"
            input-group-class="tw-mb-3"
            type="number"
            :isMandatory="true"
            :disabled="isDisabledForm"
            :error="
              assetAdditionalDataErrors['assetAdditionalData.purchase_value']
            "
            :is-error="
              assetAdditionalDataErrors['assetAdditionalData.purchase_value'] &&
              assetAdditionalDataErrors['assetAdditionalData.purchase_value']
                .length > 0
            "
            @change="
              updateField(
                true,
                'purchase_value',
                asset.additional_data.purchase_value
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.depreciation_percent"
            id="amortizationInPercent"
            name="amortizationInPercent"
            :label="$t('views.assets.amortizationInPercent')"
            input-group-class="tw-mb-3"
            type="number"
            :isMandatory="true"
            :disabled="isDisabledForm"
            :error="
              assetAdditionalDataErrors[
                'assetAdditionalData.depreciation_percent'
              ]
            "
            :is-error="
              assetAdditionalDataErrors[
                'assetAdditionalData.depreciation_percent'
              ] &&
              assetAdditionalDataErrors[
                'assetAdditionalData.depreciation_percent'
              ].length > 0
            "
            @change="
              updateField(
                true,
                'depreciation_percent',
                asset.additional_data.depreciation_percent
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.monthly_depreciation"
            id="monthlyAmortizationInPLN"
            name="monthlyAmortizationInPLN"
            :label="$t('views.assets.monthlyAmortizationInPLN')"
            input-group-class="tw-mb-3"
            type="number"
            :isMandatory="true"
            :disabled="isDisabledForm"
            :error="
              assetAdditionalDataErrors[
                'assetAdditionalData.monthly_depreciation'
              ]
            "
            :is-error="
              assetAdditionalDataErrors[
                'assetAdditionalData.monthly_depreciation'
              ] &&
              assetAdditionalDataErrors[
                'assetAdditionalData.monthly_depreciation'
              ].length > 0
            "
            @change="
              updateField(
                true,
                'monthly_depreciation',
                asset.additional_data.monthly_depreciation
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.annual_depreciation"
            id="annualAmortizationInPLN"
            name="annualAmortizationInPLN"
            :label="$t('views.assets.annualAmortizationInPLN')"
            input-group-class="tw-mb-3"
            type="number"
            :isMandatory="true"
            :disabled="isDisabledForm"
            :error="
              assetAdditionalDataErrors[
                'assetAdditionalData.annual_depreciation'
              ]
            "
            :is-error="
              assetAdditionalDataErrors[
                'assetAdditionalData.annual_depreciation'
              ] &&
              assetAdditionalDataErrors[
                'assetAdditionalData.annual_depreciation'
              ].length > 0
            "
            @change="
              updateField(
                true,
                'annual_depreciation',
                asset.additional_data.annual_depreciation
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.current_value"
            id="currentAssetValueInPLN"
            name="currentAssetValueInPLN"
            :label="$t('views.assets.currentAssetValueInPLN')"
            input-group-class="tw-mb-3"
            type="number"
            :isMandatory="true"
            :disabled="isDisabledForm"
            :error="
              assetAdditionalDataErrors['assetAdditionalData.current_value']
            "
            :is-error="
              assetAdditionalDataErrors['assetAdditionalData.current_value'] &&
              assetAdditionalDataErrors['assetAdditionalData.current_value']
                .length > 0
            "
            @change="
              updateField(
                true,
                'current_value',
                asset.additional_data.current_value
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.contract_sign_date"
            id="assetLaunchDate"
            name="assetLaunchDate"
            :label="$t('views.assets.assetLaunchDate')"
            input-group-class="tw-mb-3"
            type="date"
            :isMandatory="true"
            :labelOnBorder="true"
            :disabled="isDisabledForm"
            :error="getDateError('assetAdditionalData.contract_sign_date')"
            :is-error="
              isDateError(
                asset?.additional_data.contract_sign_date,
                'assetAdditionalData.contract_sign_date'
              )
            "
            @focusOut="
              updateField(
                true,
                'contract_sign_date',
                asset.additional_data.contract_sign_date
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.depreciation_end_date"
            id="assetAmortizationEndDate"
            name="assetAmortizationEndDate"
            :label="$t('views.assets.assetAmortizationEndDate')"
            input-group-class="tw-mb-3"
            type="date"
            :isMandatory="true"
            :labelOnBorder="true"
            :disabled="isDisabledForm"
            :error="getDateError('assetAdditionalData.depreciation_end_date')"
            :is-error="
              isDateError(
                asset?.additional_data.depreciation_end_date,
                'assetAdditionalData.depreciation_end_date'
              )
            "
            @focusOut="
              updateField(
                true,
                'depreciation_end_date',
                asset.additional_data.depreciation_end_date
              )
            "
          />
          <UIListbox
            v-model="asset.additional_data.is_withdrawn"
            id="assetWithdrawn"
            name="assetWithdrawn"
            :label="$t('views.assets.assetWithdrawn')"
            input-group-class="tw-mb-3"
            :options="assetWithdrawnOptions"
            :isMandatory="true"
            :labelOnBorder="true"
            :disabled="isDisabledForm"
            :placeholder="$t('views.assets.select')"
            :error="
              assetAdditionalDataErrors['assetAdditionalData.is_withdrawn']
            "
            :is-error="
              assetAdditionalDataErrors['assetAdditionalData.is_withdrawn'] &&
              assetAdditionalDataErrors['assetAdditionalData.is_withdrawn']
                .length > 0
            "
            @change="
              updateField(
                true,
                'is_withdrawn',
                asset.additional_data.is_withdrawn
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.expiry_date"
            id="assetExpirationDate"
            name="assetExpirationDate"
            :label="$t('views.assets.assetExpirationDate')"
            input-group-class="tw-mb-3"
            type="date"
            :isMandatory="true"
            :labelOnBorder="true"
            :disabled="isDisabledForm"
            :error="getDateError('assetAdditionalData.expiry_date')"
            :is-error="
              isDateError(
                asset?.additional_data.expiry_date,
                'assetAdditionalData.expiry_date'
              )
            "
            @focusOut="
              updateField(
                true,
                'expiry_date',
                asset.additional_data.expiry_date
              )
            "
          />
        </div>
      </div>
      <div>
        <h2 class="tw-mb-8 tw-mr-3 tw-text-xl tw-font-bold tw-text-grey34">
          {{ $t('views.assets.managementAssetInfo') }}:
        </h2>
        <div
          class="tw-grid tw-grid-cols-1 tw-gap-3 xl:tw-grid-cols-2 xl:tw-gap-6"
        >
          <UIInput
            v-model="asset.additional_data.contract_number"
            id="contractNumber"
            name="contractNumber"
            :label="$t('views.assets.contractNumber')"
            input-group-class="tw-mb-3"
            :isMandatory="true"
            :labelOnBorder="true"
            :disabled="isDisabledForm"
            :error="
              assetAdditionalDataErrors['assetAdditionalData.contract_number']
            "
            :is-error="
              assetAdditionalDataErrors[
                'assetAdditionalData.contract_number'
              ] &&
              assetAdditionalDataErrors['assetAdditionalData.contract_number']
                .length > 0
            "
            @change="
              updateField(
                true,
                'contract_number',
                asset.additional_data.contract_number
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.contract_for"
            id="contractFor"
            name="contractFor"
            :label="$t('views.assets.contractFor')"
            input-group-class="tw-mb-3"
            :isMandatory="true"
            :labelOnBorder="true"
            :disabled="isDisabledForm"
            :error="
              assetAdditionalDataErrors['assetAdditionalData.contract_for']
            "
            :is-error="
              assetAdditionalDataErrors['assetAdditionalData.contract_for'] &&
              assetAdditionalDataErrors['assetAdditionalData.contract_for']
                .length > 0
            "
            @change="
              updateField(
                true,
                'contract_for',
                asset.additional_data.contract_for
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.activation_date"
            id="contractSigningDateForAsset"
            name="contractSigningDateForAsset"
            :label="$t('views.assets.contractSigningDateForAsset')"
            input-group-class="tw-mb-3"
            type="date"
            :isMandatory="true"
            :labelOnBorder="true"
            :disabled="isDisabledForm"
            :error="getDateError('assetAdditionalData.activation_date')"
            :is-error="
              isDateError(
                asset?.additional_data.activation_date,
                'assetAdditionalData.activation_date'
              )
            "
            @focusOut="
              updateField(
                true,
                'activation_date',
                asset.additional_data.activation_date
              )
            "
          />
          <UIListbox
            v-model="asset.additional_data.responsible_person"
            id="personResponsibleForContract"
            name="personResponsibleForContract"
            :label="$t('views.assets.personResponsibleForContract')"
            input-group-class="tw-mb-3"
            :options="userList"
            :isMandatory="true"
            :labelOnBorder="true"
            :disabled="isDisabledForm"
            :placeholder="$t('views.assets.select')"
            :error="
              assetAdditionalDataErrors[
                'assetAdditionalData.responsible_person'
              ]
            "
            :is-error="
              assetAdditionalDataErrors[
                'assetAdditionalData.responsible_person'
              ] &&
              assetAdditionalDataErrors[
                'assetAdditionalData.responsible_person'
              ].length > 0
            "
            @change="
              updateField(
                true,
                'responsible_person',
                asset.additional_data.responsible_person
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.supplier_responsible_person"
            id="personResponsibleForContractOnSupplierSide"
            name="personResponsibleForContractOnSupplierSide"
            :label="
              $t('views.assets.personResponsibleForContractOnSupplierSide')
            "
            input-group-class="tw-mb-3"
            :isMandatory="true"
            :labelOnBorder="true"
            :disabled="isDisabledForm"
            :error="
              assetAdditionalDataErrors[
                'assetAdditionalData.supplier_responsible_person'
              ]
            "
            :is-error="
              assetAdditionalDataErrors[
                'assetAdditionalData.supplier_responsible_person'
              ] &&
              assetAdditionalDataErrors[
                'assetAdditionalData.supplier_responsible_person'
              ].length > 0
            "
            @change="
              updateField(
                true,
                'supplier_responsible_person',
                asset.additional_data.supplier_responsible_person
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.supplier_responsible_phone"
            id="phoneOfPersonResponsibleOnSupplierSide"
            name="phoneOfPersonResponsibleOnSupplierSide"
            :label="$t('views.assets.phoneOfPersonResponsibleOnSupplierSide')"
            input-group-class="tw-mb-3"
            :isMandatory="true"
            :labelOnBorder="true"
            :disabled="isDisabledForm"
            :error="
              assetAdditionalDataErrors[
                'assetAdditionalData.supplier_responsible_phone'
              ]
            "
            :is-error="
              assetAdditionalDataErrors[
                'assetAdditionalData.supplier_responsible_phone'
              ] &&
              assetAdditionalDataErrors[
                'assetAdditionalData.supplier_responsible_phone'
              ].length > 0
            "
            @change="
              updateField(
                true,
                'supplier_responsible_phone',
                asset.additional_data.supplier_responsible_phone
              )
            "
          />
          <UIInput
            v-model="asset.additional_data.supplier_responsible_email"
            id="emailOfPersonResponsibleOnSupplierSide"
            name="emailOfPersonResponsibleOnSupplierSide"
            :label="$t('views.assets.emailOfPersonResponsibleOnSupplierSide')"
            input-group-class="tw-mb-3"
            :isMandatory="true"
            :labelOnBorder="true"
            :disabled="isDisabledForm"
            :error="
              assetAdditionalDataErrors[
                'assetAdditionalData.supplier_responsible_email'
              ]
            "
            :is-error="
              assetAdditionalDataErrors[
                'assetAdditionalData.supplier_responsible_email'
              ] &&
              ssetAdditionalDataErrors[
                'assetAdditionalData.supplier_responsible_email'
              ].length > 0
            "
            @change="
              updateField(
                true,
                'supplier_responsible_email',
                asset.additional_data.supplier_responsible_email
              )
            "
          />
        </div>
      </div>
    </div>
    <div
      class="d-flex justify-content-center w-100 mt-4"
      v-if="isEditMode && showBasicMetricForm"
    >
      <router-link
        v-if="asset.status_id === 1"
        to="/_assets"
        class="tw-mr-6 tw-max-w-24"
      >
        <UIButton type="secondary" :label="$t('button.comeback')" />
      </router-link>
      <UIButton
        v-else
        type="secondary"
        @click="isEditMode = false"
        class="tw-mr-6 tw-max-w-24"
        :label="$t('button.comeback')"
      />

      <UIButton
        type="primary"
        :label="$t('button.saveChanges')"
        @click="saveAsset()"
      >
        <template #left>
          <font-awesome-icon
            icon="icon fa-solid fa-check"
            :class="{ icon: true }"
            class="mr-2 text-xs"
          />
        </template>
      </UIButton>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import GlossariesAssetForm from '@/components/views/assets/GlossariesAssetForm.vue'
import UIInput from '@/components/UI/UIInput.vue'
import UIRadio from '@/components/UI/UIRadio.vue'
import UIButton from '@/components/UI/UIButton.vue'
import type { SelectOption } from '@/types/CommonTypes'
import type { IGlossaryLineTicket } from '@/types/GlossariesTypes'
import {
  assetsService,
  companiesService,
  errorsService,
  glossaryService,
  userService,
} from '@/main'
import type { Nullable } from '@/types/Nullable'
import type {
  IAsset,
  IAssetAdditionalFormData,
  IAssetFormData,
} from '@/types/AssetsTypes'
import type { IUser } from '@/types/UserTypes'
import type { ICompany, ICompanyUnit } from '@/types/CompaniesTypes'
import { errorsEnum, type IError, type IErrorElement } from '@/types/ErrorTypes'
import UIListbox from '@/components/UI/UIListbox.vue'
import { isValidDate } from '@fullcalendar/core/internal'

interface IGeneralMetricsForm {
  company: string
  unit: string
  systemOrServer: string
  abbreviatedSystemOrServer: string
  assetDescription: string
  typeOfAsset: string
  technicalOwner: string
  substituteTechnicalOwner: string
  businessOwner: string
  substituteBizOwner: string
  materialAsset: boolean
}

@Component({
  components: {
    UIListbox,
    UIButton,
    UIRadio,
    UIInput,
    GlossariesAssetForm,
  },
  emits: ['reloadForm'],
})
export default class GeneralMetricsTab extends Vue {
  @Prop({ required: true }) public lines!: IGlossaryLineTicket[]

  public showBasicMetricForm: boolean = false

  public setShowBasicMetricForm(value: boolean): void {
    this.showBasicMetricForm = value
  }

  public get ticketLines(): IGlossaryLineTicket[] {
    return glossaryService.ticketLines
  }

  public isEditMode: boolean = false

  public get users(): IUser[] {
    return userService.users
  }

  public get ticketId(): Nullable<number> {
    return this.asset?.ticket_id ?? null
  }

  public get companies(): ICompany[] {
    return companiesService.companies
  }

  public get companiesUnits(): ICompanyUnit[] {
    return companiesService.units
  }

  public get userList(): SelectOption[] {
    return this.users.map((user) => ({
      value: user.id,
      label: user.first_name + ' ' + user.last_name,
    }))
  }

  public get companiesList(): SelectOption[] {
    return this.companies.map((company) => ({
      value: company.id,
      label: company.name,
    }))
  }

  public get unitsList(): SelectOption[] {
    return this.companiesUnits.map((unit) => ({
      value: unit.id,
      label: unit.name,
    }))
  }

  public get isGlossaryFormEnd(): boolean {
    if (this.ticketLines.length === 0) return false
    const lastElement = this.ticketLines.pop()
    return lastElement?.line?.end === 1 ?? false
  }

  public get asset(): Nullable<IAsset> {
    return assetsService.asset
  }

  public form: IGeneralMetricsForm = {
    company: '',
    unit: '',
    systemOrServer: '',
    abbreviatedSystemOrServer: '',
    assetDescription: '',
    typeOfAsset: '',
    technicalOwner: '',
    substituteTechnicalOwner: '',
    businessOwner: '',
    substituteBizOwner: '',
    materialAsset: false,
  }

  public get isLoading(): boolean {
    return assetsService.isLoading
  }

  public scrollToErrorElement(): void {
    setTimeout(() => {
      const errorElements = document.getElementsByClassName('isError')
      if (errorElements.length > 0) {
        this.$scrollToElement(errorElements[0], 'center')
      }
    }, 300)
  }

  public get isDisabledForm(): boolean {
    return (
      ((!this.asset || !this.asset.id || this.asset.status_id > 1) &&
        !this.isEditMode) ||
      this.isLoading
    )
  }

  public get typeOfAssetOptions(): SelectOption[] {
    return [
      { value: 1, label: this.$t('views.assets.types.system') },
      { value: 2, label: this.$t('views.assets.types.application') },
      { value: 3, label: this.$t('views.assets.types.mainProcess') },
      { value: 4, label: this.$t('views.assets.types.subProcess') },
      { value: 5, label: this.$t('views.assets.types.helperProcess') },
      { value: 6, label: this.$t('views.assets.types.person') },
    ]
  }

  public get assetWithdrawnOptions(): SelectOption[] {
    return [
      { value: 1, label: this.$t('views.assets.withdrawOption.yes') },
      { value: 0, label: this.$t('views.assets.withdrawOption.no') },
    ]
  }

  public async mounted(): Promise<void> {
    errorsService.clearScopeErrors(errorsEnum.SaveAsset)
    await userService.loadUsers()
    await companiesService.loadCompanies()
    if (this.asset!.status_id === 1) {
      this.isEditMode = true
    }
    if (this.asset!.company_id) {
      await companiesService.loadUnits(this.asset!.company_id)
    }
    if (this.asset!.additional_data.is_tangible === null) {
      this.asset!.additional_data.is_tangible = 0
    }
  }

  public get additionalFields(): string[] {
    return [
      'is_tangible',
      'purchase_value',
      'depreciation_percent',
      'monthly_depreciation',
      'annual_depreciation',
      'current_value',
      'contract_sign_date',
      'depreciation_end_date',
      'is_withdrawn',
      'expiry_date',
      'contract_number',
      'contract_for',
      'activation_date',
      'responsible_person',
      'supplier_responsible_person',
      'supplier_responsible_phone',
      'supplier_responsible_email',
    ]
  }

  public get assetBaseFields(): string[] {
    return [
      'company_id',
      'organizational_unit',
      'name',
      'short_name',
      'description',
      'type',
      'technical_owner',
      'deputy_technical_owner',
      'business_owner',
      'deputy_business_owner',
    ]
  }

  public async handleUpdateRadio(value: number): Promise<void> {
    await this.updateField(true, 'is_tangible', value)
  }
  public async updateField(
    isAssetAdditional: boolean,
    field: string,
    value: unknown
  ): Promise<void> {
    errorsService.clearScopeErrors(errorsEnum.SaveAsset)
    if (this.asset && this.asset.id) {
      let additional: IAssetAdditionalFormData = {}
      let data: IAssetFormData = {}
      if (isAssetAdditional) {
        // @ts-ignore
        additional[field] = value
      } else {
        // @ts-ignore
        data[field] = value
      }
      await assetsService
        .updateAssetField(this.asset.id, data, additional)
        .then(() => {})
        .catch(() => {})
    }
  }

  public async updateCompanyField(value: unknown): Promise<void> {
    await this.updateField(false, 'company_id', value).then(async () => {
      await this.updateField(false, 'organizational_unit', null).then(
        async () => {
          if (this.asset) {
            this.asset.organizational_unit = null
          }
          await companiesService.loadUnits(value as number)
        }
      )
    })
  }

  public isInvalidDate(date: string): boolean {
    const dateObj = new Date(date)
    if (dateObj.toString() === 'Invalid Date') {
      return true
    }

    const startDate = new Date('1900-01-01')
    const endDate = new Date('2200-01-01')
    return dateObj < startDate || dateObj > endDate
  }

  public isDateError(value: string, objectName: string): boolean {
    if (this.isInvalidDate(value)) {
      return true
    }
    return this.errors[objectName] && this.errors[objectName].length > 0
  }

  public getDateError(value: string, objectName: string): string {
    if (this.isInvalidDate(value)) {
      return this.$t('common.invalidDate')
    }
    return this.assetAdditionalDataErrors[objectName]
  }

  public get errors(): IError {
    return errorsService.getScopeErrors(errorsEnum.SaveAsset)
  }

  public get baseDataErrors(): IErrorElement[] {
    const errors = Object.entries(this.errors)
    const transformErrors: IErrorElement[] = []
    errors.forEach(([key, value]) => {
      if (key.search('assetData.') > -1) {
        // eslint-disable-next-line
        transformErrors[key] = value[0]
      }
    })
    return transformErrors
  }

  public get assetAdditionalDataErrors(): IErrorElement[] {
    const errors = Object.entries(this.errors)
    const transformErrors: IErrorElement[] = []
    errors.forEach(([key, value]) => {
      if (key.search('assetAdditionalData.') > -1) {
        // eslint-disable-next-line
        transformErrors[key] = value[0]
      }
    })
    return transformErrors
  }

  public async saveAsset(): Promise<void> {
    errorsService.clearScopeErrors(errorsEnum.SaveAsset)
    if (this.asset && this.asset.id) {
      const id = this.asset.id
      let additional: IAssetAdditionalFormData = {}
      let data: IAssetFormData = {}

      this.additionalFields.forEach((field) => {
        if (this.asset.additional_data[field] !== null) {
          additional[field] = this.asset.additional_data[field]
        }
      })

      this.assetBaseFields.forEach((field) => {
        data[field] = this.asset[field]
      })

      await assetsService
        .saveAsset(this.asset.id, data, additional)
        .then(async () => {
          if (
            this.assetAdditionalDataErrors.length > 0 ||
            this.baseDataErrors.length > 0
          ) {
            this.scrollToErrorElement()
            return
          }

          await assetsService.loadAsset(id).then(async () => {
            if (this.ticketId) {
              await glossaryService.loadGlossaryForTicket(this.ticketId)
              if (this.asset && this.asset.id && this.asset?.status_id > 1) {
                this.isEditMode = false
              }
            }
          })
        })
        .catch(() => {
          this.scrollToErrorElement()
        })
    }
  }
}
</script>
