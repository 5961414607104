import type { Nullable } from '@/types/Nullable'

export function isError(val: Nullable<string[]>): boolean {
  return Boolean(val && val.length > 0)
}

export function getError(val: Nullable<string[]>): string {
  if (!isError(val) || !val) return ''
  return val.join(', ')
}
