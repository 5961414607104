import { Store, Pinia } from 'pinia-class-component'
import type { IAttribute } from '@/types/AttributesTypes'
import AttributesRepository from '@/repositories/AttributesRepository'
import type { Nullable } from '@/types/Nullable'
import type { AxiosError } from 'axios'

interface IAttributesStore {
  attributes: IAttribute[]
  attribute: Nullable<IAttribute>
  isLoading: boolean
  isError: boolean
}

@Store
export default class AttributesService extends Pinia {
  public store: IAttributesStore = {
    attributes: [],
    attribute: null,
    isLoading: false,
    isError: false,
  }

  public get attributes(): IAttribute[] {
    return this.store.attributes
  }

  public get attribute(): Nullable<IAttribute> {
    return this.store.attribute
  }

  public get isLoading(): boolean {
    return this.store.isLoading
  }

  public get isError(): boolean {
    return this.store.isError
  }

  public async loadAttributes(): Promise<void> {
    this.store.isLoading = true

    await AttributesRepository.getAttributes()
      .then((response) => {
        this.store.attributes = response
      })
      .catch(() => {
        this.store.attributes = []
        this.store.isError = true
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async loadAttribute(id: number): Promise<void> {
    this.store.isLoading = true
    await AttributesRepository.getAttribute(id)
      .then((response) => {
        this.store.attribute = response
        return
      })
      .catch((e) => {
        this.store.attribute = null
        throw e
      })
      .finally(() => {
        this.store.isLoading = false
      })
  }

  public async addAttribute(form: IAttribute): Promise<void> {
    this.store.isLoading = true
    await AttributesRepository.add(form)
      .then(() => {
        this.store.isLoading = false
      })
      .catch((error: Error | AxiosError) => {
        this.store.isLoading = false
        throw error
      })
  }

  public async updateAttribute(id: number, form: IAttribute): Promise<void> {
    await AttributesRepository.update(id, form)
      .then((response) => {
        this.store.attribute = response
        this.store.isLoading = false
      })
      .catch(() => {
        this.store.isLoading = false
      })
  }
}
