<template>
  <Transition name="base" appear>
    <slot />
  </Transition>
</template>

<style>
.base-enter-active,
.base-leave-active {
  transition: opacity 2.5s ease;
}

.base-enter-from,
.base-leave-to {
  opacity: 0;
}
</style>
