export default {
  sources: {
    newSource: 'Nowe źródło',
    editSource: 'Edytuj źródło',
    hiddenSources: 'Ukryte źródła',
    hidden: 'Ukryte',
    notHidden: 'Nieukryte',
    active: 'Aktywne',
    notActive: 'Nieaktywne',
  },
}
