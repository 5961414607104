<template>
  <div
    class="c-uilistbox"
    :class="{ 'tw-w-full': !calculateWidth }"
    :style="{ width: clientWidth }"
  >
    <div ref="longestRef" class="hide-div">{{ longestOption }}</div>
    <Listbox
      :value="modelValue"
      @update:model-value="updateModelValue"
      :disabled="disabled"
      v-slot="{ open }"
    >
      <div class="tw-relative">
        <ListboxLabel
          :class="{
            'tw-absolute tw-left-[0.4rem] tw-top-[-0.5rem] tw-z-10 tw-bg-white tw-p-0.5 tw-text-xs dark:tw-bg-neutral-950 dark:tw-text-greyc9':
              !labelOnTop,
          }"
        >
          {{ label }}
        </ListboxLabel>

        <ListboxButton
          class="tw-focus-visible:ring-2 tw-focus-visible:ring-white/75 tw-focus-visible:ring-offset-2 tw-focus-visible:ring-offset-orange-300 tw-sm:text-sm tw-relative tw-w-full tw-cursor-default tw-rounded-md tw-border tw-border-greyd2 tw-bg-white tw-py-[9px] tw-pl-3 tw-pr-10 tw-text-left tw-shadow-md tw-outline-0 focus:tw-outline-none focus-visible:tw-border-brand dark:tw-bg-neutral-950 dark:tw-text-greyc9"
        >
          <div class="tw-min-h-6">{{ previewLabel }}</div>
          <span
            class="tw-pointer-events-none tw-absolute tw-inset-y-0 tw-right-0 tw-flex tw-items-center tw-pr-2"
          >
            <font-awesome-icon
              icon="icon fa-solid fa-chevron-down"
              class="icon tw-pointer-events-none tw-text-xs tw-transition-transform"
              :class="{
                'tw-rotate-180': open,
              }"
            />
          </span>
        </ListboxButton>

        <transition
          leave-active-class="tw-transition tw-duration-100 tw-ease-in"
          leave-from-class="tw-opacity-100"
          leave-to-class="tw-opacity-0"
        >
          <ListboxOptions
            class="tw-absolute tw-z-50 tw-mt-1 tw-max-h-96 tw-min-w-full tw-overflow-auto tw-rounded-md tw-bg-white tw-py-1 tw-text-base tw-shadow-lg tw-ring-1 tw-ring-black/5 focus:tw-outline-none dark:tw-bg-neutral-950"
          >
            <ListboxOption
              v-slot="{ active }"
              v-for="option in options"
              :key="option.label"
              :value="option.value"
              as="template"
            >
              <li
                class="tw-break tw-relative tw-max-w-full tw-cursor-pointer tw-select-none tw-overflow-ellipsis tw-break-words tw-py-2 tw-pl-10 tw-pr-2"
                :class="{
                  'tw-bg-brand tw-text-white': active,
                  'tw-text-gray-900 dark:tw-text-greyc9': !active,
                }"
              >
                <span
                  :class="[
                    modelValue === option.value
                      ? 'tw-font-medium'
                      : 'tw-font-normal',
                    'tw-block',
                  ]"
                >
                  {{ option.label }}
                </span>
                <span
                  v-if="modelValue === option.value"
                  class="tw-absolute tw-inset-y-0 tw-left-0 tw-flex tw-items-center tw-pl-3"
                >
                  <font-awesome-icon
                    icon="icon fa-solid fa-check"
                    class="tw-text-md"
                  />
                </span>
              </li>
            </ListboxOption>
          </ListboxOptions>
        </transition>
      </div>
    </Listbox>

    <UIError :isError="isError" :error="error" :errorClass="errorClass" />
  </div>
</template>

<script lang="ts">
import { Component, Vue, Ref, Prop, Watch } from 'vue-facing-decorator'
import {
  Listbox,
  ListboxLabel,
  ListboxButton,
  ListboxOptions,
  ListboxOption,
} from '@headlessui/vue'
import type { SelectOption } from '@/types/CommonTypes'
import UIError from '@/components/UI/UIError.vue'
@Component({
  components: {
    UIError,
    Listbox,
    ListboxLabel,
    ListboxButton,
    ListboxOption,
    ListboxOptions,
  },
  emits: ['update:modelValue'],
})
export default class UIListbox extends Vue {
  @Ref('longest')
  public longestRef!: HTMLDivElement

  @Prop({ default: [] })
  public options!: SelectOption[]

  @Prop({ required: false, default: false }) public calculateWidth!: boolean
  @Prop({ required: false }) public placeholder!: string
  @Prop({ required: false }) public label!: string
  @Prop({ required: false }) public labelOnTop!: boolean
  @Prop({ required: true }) public modelValue!: SelectOption['value']
  @Prop({ required: false, default: false }) public disabled!: boolean

  @Prop({ required: false }) public errorClass!: string
  @Prop({ required: false }) public error!: string
  @Prop({ required: false }) public isError!: boolean

  public clientWidth = 'auto'

  public get longestOption(): string {
    let longest = ''
    this.options.forEach((option) => {
      if (!option.label) {
        return
      }

      if (typeof option.label === 'number') {
        option.label = option.label.toString()
      }

      if (option.label.length > longest.length) {
        longest = option.label
      }
    })
    return longest
  }
  public getClientWidth(): string {
    if (!this.longestRef) return 'auto'
    const clientWidth = this.longestRef.clientWidth
    // in case for whatever reason we didnt get any width
    if (clientWidth <= 0) {
      const comparisionString = this.longestOption ?? this.placeholder
      const widthGuess = comparisionString.length * 8.4
      return `${widthGuess + 60}px`
    }
    return `${this.longestRef.clientWidth + 60}px`
  }

  public get previewLabel(): string {
    const findOption = this.options.find((option) => {
      if (
        typeof option.value != typeof this.modelValue &&
        this.modelValue !== null &&
        this.modelValue !== undefined
      ) {
        console.warn(
          `Option value type is different than modelValue type. Option value: ${typeof option.value}, modelValue: ${typeof this
            .modelValue}`
        )
      }
      return option.value === this.modelValue
    })
    if (!findOption?.label) return this.placeholder
    return String(findOption.label)
  }

  public updateModelValue(value: SelectOption['value']): void {
    this.$emit('update:modelValue', value)
    this.$emit('change', value)
  }

  public mounted(): void {
    if (this.calculateWidth) {
      this.clientWidth = this.getClientWidth()
    }
  }

  @Watch('options')
  public onOptionsChange(): void {
    if (this.calculateWidth) {
      this.clientWidth = this.getClientWidth()
    }
  }
}
</script>

<style scoped lang="scss">
.c-uilistbox {
  ul {
    margin: 0;
    padding: 0;
  }
  .hide-div {
    position: absolute;
    visibility: hidden;
    height: auto;
    width: auto;
    white-space: nowrap;
  }
}
</style>
