<template>
  <nav class="c-dashboardMenu menu">
    <TransitionGroup name="images" tag="div" class="logo">
      <img
        :src="$t('company.logoIconSRC')"
        class="small-brand-img"
        alt="main_logo"
        key="logo-1"
        v-if="show"
      />
      <router-link key="logo-2" to="/">
        <img
          :src="$t('company.logoSRC')"
          class="brand-img"
          alt="main_logo"
          v-if="!show"
        />
      </router-link>
    </TransitionGroup>
    <div class="menu-title">{{ $t('menu.title') }}</div>
    <div class="links">
      <div v-for="link in menu" :key="`menu-${link.label}`">
        <AccessContainer
          :disabled-permission="!link.permission || disabledPermission"
          :permission="link.permission"
        >
          <a
            :href="link.route"
            class="link"
            @click.prevent="handleReroute(link.route)"
            v-show="checkPermission(link.permissionName)"
          >
            <span class="icon">
              <font-awesome-icon :icon="link.icon" />
            </span>
            <TransitionGroup name="links-label" tag="span" class="links-label">
              <span v-if="!show" :key="`labelLink_${link.label}`">{{
                link.label
              }}</span>
            </TransitionGroup>
          </a>
        </AccessContainer>
      </div>
    </div>
    <template v-if="isAdmin && showSettingsMenuSection">
      <AccessContainer :permission="SettingsTicketsMenu">
        <div class="menu-title admin">
          <template v-if="!show">{{ $t('menu.titleSettings') }}</template>
        </div>
        <div class="links">
          <div v-for="link in menuAdmin" :key="`menu-${link.label}`">
            <AccessContainer
              :disabled-permission="!link.permission || disabledPermission"
              :permission="link.permission"
            >
              <a
                :href="link.route"
                class="link"
                v-show="checkPermission(link.permissionName)"
                @click.prevent="handleReroute(link.route)"
              >
                <span class="icon">
                  <font-awesome-icon :icon="link.icon" />
                </span>
                <TransitionGroup
                  name="links-label"
                  tag="span"
                  class="links-label"
                >
                  <span :key="`labelLink2_${link.label}`" v-if="!show">{{
                    link.label
                  }}</span>
                </TransitionGroup>
              </a>
            </AccessContainer>
          </div>
        </div>
      </AccessContainer>
    </template>
  </nav>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import type { MenuLink } from '@/types/MenuTypes'
import { environmentsManager, userService } from '@/main'
import {
  SettingsAttributesMenu,
  SettingsCompaniesMenu,
  SettingsConsentsMenu,
  SettingsGlossariesMenu,
  SettingsParametersMenu,
  SettingsRolesMenu,
  SettingsTicketsMenu,
  SettingsUserMenu,
  TicketsMenu,
} from '@/types/PermissionsModules'

@Component
export default class DashboardMenu extends Vue {
  @Prop({ default: false }) public show!: boolean
  public SettingsUserMenu = SettingsUserMenu
  public SettingsGlossariesMenu = SettingsGlossariesMenu
  public SettingsCompaniesMenu = SettingsCompaniesMenu
  public SettingsAttributesMenu = SettingsAttributesMenu
  public SettingsParametersMenu = SettingsParametersMenu
  public SettingsRolesMenu = SettingsRolesMenu
  public SettingsConsentsMenu = SettingsConsentsMenu
  public TicketsMenu = TicketsMenu
  public SettingsTicketsMenu = SettingsTicketsMenu

  public get permissions(): IMenuItem[] {
    return userService.menuItems
  }

  public checkPermission(menuItemName): boolean {
    if (this.disabledPermission) return true
    const item = this.permissions[menuItemName]
    if (item) {
      return item.active
    }

    return true
  }

  public get disabledPermission(): boolean {
    return environmentsManager.PERMISSIONS_ENABLED === 'false'
  }

  public get showSettingsMenuSection(): boolean {
    return this.menuAdmin.some((item) =>
      this.checkPermission(item.permissionName)
    )
  }

  public get menu(): MenuLink[] {
    return [
      {
        icon: 'icon fa-solid fa-list',
        label: this.$t('menu.tickets'),
        route: '/tickets',
        permissionName: 'tickets',
        permission: this.TicketsMenu,
      },
      {
        icon: 'icon fa-solid fa-clipboard-list',
        label: this.$t('menu.tasks'),
        route: '/tasks',
        permissionName: 'tasks',
      },
      {
        icon: 'icon fa-solid fa-calendar-days',
        label: this.$t('menu.calendar'),
        route: '/calendar',
        permissionName: 'tasks',
      },
      {
        icon: 'icon fa-solid fa-laptop',
        label: this.$t('menu.assets'),
        route: '/_assets',
        permissionName: 'assets',
      },
      {
        icon: 'icon fa-solid fa-fire',
        label: this.$t('menu.risks'),
        route: '/risks',
        permissionName: 'risks',
      },
      {
        icon: 'icon fa-solid fa-envelope',
        label: this.$t('menu.affairs'),
        route: '/affairs',
      },
    ]
  }

  public get menuAdmin(): MenuLink[] {
    return [
      {
        icon: 'icon fa-solid fa-users',
        label: this.$t('menu.users'),
        route: '/users',
        permissionName: 'users',
        permission: this.SettingsUserMenu,
      },
      {
        icon: 'icon fa-solid fa-book',
        label: this.$t('menu.glossaries'),
        route: '/glossaries',
        permissionName: 'glossaries',
        permission: this.SettingsGlossariesMenu,
      },
      {
        icon: 'icon fa-regular fa-building',
        label: this.$t('menu.companies'),
        route: '/companies',
        permissionName: 'companies',
        permission: this.SettingsCompaniesMenu,
      },
      {
        icon: 'icon fa-solid fa-bookmark',
        label: this.$t('menu.attributes'),
        route: '/attributes',
        permissionName: 'attributes',
        permission: this.SettingsAttributesMenu,
      },
      {
        icon: 'icon fa-regular fa-list-alt',
        label: this.$t('menu.parameters'),
        route: '/parameters',
        permissionName: 'parameters',
        permission: this.SettingsParametersMenu,
      },
      {
        icon: 'icon fa-solid fa-people-group',
        label: this.$t('menu.roles'),
        route: '/roles',
        permissionName: 'roles',
        permission: this.SettingsRolesMenu,
      },
      {
        icon: 'icon fa-solid fa-id-badge',
        label: this.$t('menu.identification'),
        route: '/identification',
      },
      {
        icon: 'icon fa-solid fa-user-shield',
        label: this.$t('menu.consents'),
        route: '/consents',
        permissionName: 'consents',
        permission: this.SettingsConsentsMenu,
      },

      {
        icon: 'icon fa-solid fa-plug',
        label: this.$t('menu.sources'),
        route: '/sources',
        permissionName: 'sources',
      },
      {
        icon: 'icon fa-solid fa-bell',
        label: this.$t('menu.notificationsMenu'),
        route: '/notifications',
        permissionName: 'notifications',
      },
    ]
  }

  public async handleReroute(route: string): Promise<void> {
    userService.reauth().then(() => {
      this.$router.push(route)
    })
  }

  public get isAdmin(): boolean {
    return true
  }
}
</script>

<style lang="scss" scoped>
.menu {
  border-radius: 12px;
  padding-bottom: 10px;
  background: #212529;
  overflow: hidden;

  &:hover {
    @media (max-height: 713px) {
      overflow-y: scroll;
    }
  }
}

.menu-title {
  margin: 10px 16px;
  font-size: 10px;
  line-height: 15px;
  letter-spacing: 0.2em;
  text-align: left;
  color: #adb5bd;
  text-transform: uppercase;
  font-weight: 400;

  &.admin {
    border-top: 1px solid #adb5bd;
    padding-top: 15px;
    margin-top: 20px;
  }
}

.brand-img {
  width: 100%;
  padding: 20px;
}

.small-brand-img {
  width: 38px;
  padding: 23px;
  box-sizing: content-box;
}

.logo {
  position: relative;
}

.images-enter-active,
.images-leave-active,
.links-label-enter-active,
.links-label-leave-active {
  transition: all 0.5s ease;
}
.images-enter-from,
.images-leave-to,
.links-label-enter-from,
.links-label-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* ensure leaving items are taken out of layout flow so that moving
   animations can be calculated correctly. */
.images-leave-active,
.links-label-leave-active {
  position: absolute;
}

.links {
  padding: 0 16px;

  .link {
    padding: 12px 16px;
    margin-bottom: 2px;
    color: #fff;
    display: flex;
    font-size: 14px;
    text-decoration: none;
    background: none;
    transition-duration: 0.4s;
    border-radius: 5px;

    &:hover {
      background: #394046;
    }

    &.active {
      background: #545d66;
    }

    :deep(.svg-inline--fa) {
      margin-right: 10px;
      font-size: 22px;
    }

    span {
      line-height: 22px;
      text-decoration: none;
    }

    .links-label {
      margin-left: 10px;
    }
  }
}

.icon {
  width: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
  max-height: 15px;
}
</style>
