<template>
  <div
    class="tw-relative tw-flex tw-bg-greyf8 dark:tw-bg-neutral-950 dark:tw-text-white"
  >
    <GlobalModalController />
    <UITransition>
      <LoaderModal v-if="loader" />
    </UITransition>
    <div class="menu-container" :class="{ small: show }">
      <button
        @click="show = !show"
        class="hidden-button tw-bg-white dark:tw-bg-neutral-800"
      >
        <font-awesome-icon v-if="!show" icon="fa-solid fa-chevron-left" />
        <font-awesome-icon v-if="show" icon="fa-solid fa-chevron-right" />
      </button>
      <DashboardMenu :show="show" />
    </div>
    <div class="layout-container tw-min-h-screen">
      <DashboardHeader />
      <Transition name="fade" mode="out-in">
        <main
          :key="String($route.name)"
          :class="[
            'tw-rounded-xl tw-bg-white tw-p-6 tw-shadow-lg tw-shadow-greye5 dark:tw-bg-neutral-900 dark:tw-text-white',
            contentClass,
          ]"
        >
          <RouterView />
        </main>
      </Transition>
      <NewVersionNotification v-if="showUpdateNotification" />
      <p class="tw-ml-6 tw-mt-4 tw-text-sm tw-text-grey7b">
        © 2023, NISSYSTEMS, v0.5.0
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import DashboardHeader from '@/components/layouts/dashboardLayout/DashboardHeader.vue'
import DashboardMenu from '@/components/layouts/dashboardLayout/DashboardMenu.vue'
import LoaderModal from '@/components/common/LoaderModal.vue'
import {
  consentsModalService,
  environmentsManager,
  loaderService,
  userService,
} from '@/main'
import GlobalModalController from '@/components/modals/GlobalModalController.vue'
import UITransition from '@/components/UI/UITransition.vue'
import NewVersionNotification from '@/components/layouts/NewVersionNotification.vue'

@Component({
  components: {
    NewVersionNotification,
    UITransition,
    LoaderModal,
    DashboardHeader,
    DashboardMenu,
    GlobalModalController,
  },
})
export default class DashboardLayout extends Vue {
  @Prop({ required: false })
  public contentClass!: string

  public show: boolean = false

  public get loader(): boolean {
    return loaderService.showLoadingRequest || userService.reauthStatus
  }

  public get showUpdateNotification(): boolean {
    return environmentsManager.VERSION_NOTIFICATION
  }

  public async beforeMount(): Promise<void> {
    await consentsModalService.loadConsents()
  }
}
</script>

<style lang="scss">
.hidden-button {
  position: absolute;
  right: -32px;
  top: 18px;
  border-radius: 12px;
  width: 32px;
  height: 32px;
  box-shadow: 0px 0px 4px 0px #0000001a;

  span {
    position: relative;
  }
}

.menu-container {
  width: 300px;
  padding: 16px;
  transition: all 0.5s ease;
  position: relative;

  &.small {
    width: 116px;
  }
}

.layout-container {
  width: calc(100% - 300px);
  flex: 1;
  padding-right: 24px;
  display: flex;
  flex-direction: column;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.tooltip {
  width: max-content;
  max-width: 450px;
}
</style>
