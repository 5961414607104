import client from '@/services/client'
import type {
  IConsent,
  IConsentCompany,
  IConsentSendForm,
} from '@/types/ConsentsTypes'
import type { Nullable } from '@/types/Nullable'
import { isAxiosError } from 'axios'
import { errorsService } from '@/main'
import { errorsEnum } from '@/types/ErrorTypes'

class ConsentRepository {
  public async getConsents(): Promise<IConsent[]> {
    return await client
      .get(`consents`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetManyConsents,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getConsentsForAccept(): Promise<IConsent[]> {
    return await client
      .get(`consents?filterBy[active]=1`)
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetManyConsents,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getConsent(id: number | string): Promise<Nullable<IConsent>> {
    return await client
      .get(`consents/${id}`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetOneConsent,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getCompanies(): Promise<IConsentCompany[]> {
    return await client
      .get(`companies/list`)
      .then((response) => {
        return response.data.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetManyConsentCompanies,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async updateConsent(
    consent: IConsentSendForm
  ): Promise<Nullable<IConsent>> {
    return await client
      .put(`consents/${consent.id}`, {
        consentData: { ...consent.consentData },
        companiesArr: consent.companiesArr,
      })
      .then((response) => {
        return response.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.UpdateConsent,
            response: error.response,
          })
        }

        throw error
      })
  }

  public async addConsent(
    payload: IConsentSendForm
  ): Promise<Nullable<IConsent>> {
    return await client
      .post('consents', {
        consentData: { ...payload.consentData },
        companiesArr: payload.companiesArr,
      })
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.AddConsent,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async deleteConsent(id: number): Promise<Nullable<IConsent>> {
    return await client
      .delete(`consents/${id}`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) throw error
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.DeleteConsent,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async approveConsent(id: number): Promise<void> {
    await client.post(`consents/user?type_id=${id}`).catch((error) => {
      if (isAxiosError(error)) {
        if (!error.response) throw error
        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.ApproveConsent,
          response: error.response,
        })
      }
      throw error
    })
  }
}

export default new ConsentRepository()
