import { Store, Pinia } from 'pinia-class-component'
import type { IConsentsModalStore } from '@/types/ModalTypes'
import type { IConsent } from '@/types/ConsentsTypes'
import { toast } from 'vue3-toastify'
import { i18n, userService } from '@/main'
import ConsentRepository from '@/repositories/ConsentRepository'
/* import ConsentRepository from '@/repositories/ConsentRepository' */

@Store
export default class ConsentsModalService extends Pinia {
  public store: IConsentsModalStore = {
    isLoading: false,
    isError: false,
    completedConsents: [],
    consents: [],
  }

  public get getUnacceptedConsents(): IConsent[] {
    return this.store.consents.filter(
      (consent) => !this.store.completedConsents.includes(consent.id)
    )
  }

  public get getCompletedConsents(): string {
    const completed = this.store.completedConsents.length
    const total = this.store.consents.length
    return `${completed}/${total}`
  }

  public get isOpen(): boolean {
    return this.getUnacceptedConsents.length > 0
  }

  public get isLoading(): boolean {
    return this.store.isLoading
  }

  public get isError(): boolean {
    return this.store.isError
  }

  public async accept(id: number, typeId: number): Promise<void> {
    await ConsentRepository.approveConsent(typeId)
      .then(() => {
        this.store.completedConsents.push(id)
        const message = i18n.global.t('notifications.consentsAccepted')
        toast(message, {
          type: 'success',
          position: 'top-right',
          transition: 'slide',
        })
      })
      .catch(() => {
        const message = i18n.global.t('notifications.consentsAcceptedError')
        toast(message, {
          type: 'error',
          position: 'top-right',
          transition: 'slide',
        })
      })
  }

  public async loadConsents(): Promise<void> {
    const user = userService.getUser

    const companyId = user?.context?.unit_id
    await ConsentRepository.getConsentsForAccept().then((consents) => {
      this.store.consents = consents.filter((consent) => {
        const companyConsents = consent.consent_types_company?.filter(
          (company) => company.company_id == companyId
        )

        if (companyConsents && companyConsents.length > 0) {
          if (
            consent.consents_accepted &&
            consent.consents_accepted.length > 0
          ) {
            return !consent.consents_accepted.some(
              (accepted) => accepted.user_id === user?.id
            )
          } else {
            return true
          }
        }
        return false
      })
    })
  }
}
