<template>
  <div class="c-uiToggle" :class="uiClass">
    <div
      class="form-check form-switch !tw-pl-0"
      @click="$emit('update:modelValue', !modelValue)"
    >
      <input
        type="checkbox"
        role="switch"
        :id="name"
        :name="name"
        class="form-check-input tw-mr-2"
        v-bind="$attrs"
        :checked="modelValue"
      />
      <label
        :for="name"
        class="form-check-label -tw-mt-0.5"
        v-if="label"
        @click="$emit('update:modelValue', modelValue)"
      >
        {{ label }}
      </label>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { TranslateResult } from 'vue-i18n'

@Component
export default class UIToggle extends Vue {
  @Prop()
  public modelValue: boolean

  @Prop({ required: false })
  public label: string | TranslateResult

  @Prop({ required: false })
  public uiClass: string

  @Prop()
  public name: string
}
</script>

<style lang="scss" scoped>
.form-switch .form-check-input {
  background-color: #7b809a;
}
.form-switch .form-check-input:after {
  border-color: #344767;
}

.form-switch .form-check-input:checked {
  border-color: #d81b60;
  background-color: #d81b60;
}
.form-switch .form-check-input:checked:after {
  border-color: #d81b60;
}
</style>
