import type { App } from 'vue'

declare module '@vue/runtime-core' {
  interface ComponentCustomProperties {
    $scrollToElement: (elementRef: HTMLElement | null, block: 'string') => void
  }
}

export default {
  install(app: App): void {
    app.config.globalProperties.$scrollToElement = (
      elementRef: HTMLElement | null,
      block: 'string' = 'start'
    ): void => {
      if (elementRef) {
        elementRef.scrollIntoView({
          behavior: 'smooth',
          block: block,
        })
      }
    }
  },
}
