<template>
  <template v-if="access">
    <slot />
  </template>
  <template v-else-if="customContentNoAccess">
    <slot name="no-access" />
  </template>
  <span class="tw-hidden" :title="$t('common.noAccess')" v-else></span>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-facing-decorator'
import { type IPermArray } from '@/types/PermissionsModules'
import { userService } from '@/main'
@Component
export default class AccessContainer extends Vue {
  @Prop({ required: true }) public permission!: IPermArray
  @Prop({ required: false, default: false }) public disabledPermission!: boolean

  @Prop({ required: false, default: false })
  public customContentNoAccess!: boolean

  public get access(): boolean {
    if (this.disabledPermission) return true
    return userService.checkAccess(this.permission)
  }
}
</script>
