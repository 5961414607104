export default {
  users: {
    systemUsers: 'Użytkownicy systemu',
    addTitle: 'Dodaj użytkownika',
    email: 'Email',
    firstName: 'Imię',
    lastName: 'Nazwisko',
    phone: 'Numer telefonu',
    loginAt: 'Data zalogowania',
    logoutAt: 'Data wylogowania',
    activated: 'Aktywowane',
    emailVerified: 'Email zweryfikowany',
    actions: 'Akcje',
    resetAuth: 'Resetuj autoryzację',
    edit: 'Edytuj',
    block: 'Zablokuj',
    unblock: 'Odblokuj',
    delete: 'Usuń',
    notificationSuccess: 'Użytkownik został dodany',
    permissions: {
      permissions: 'Uprawnienia',
      dictionaries: 'Słowniki',
      users: 'Użytkownicy',
      reports: 'Zgłoszenia',
      roles: 'Role',
      reportCategory: 'Kategorie tematyczne',
      profile: 'Profil',
      firstName: 'Imię',
      lastName: 'Nazwisko',
      email: 'Email',
      phone: 'Numer telefonu',
      companyAffiliation: 'Przynależność do firmy',
      selectDepartament: 'Wybierz departament',
      authorization: {
        title: 'Autoryzacja',
        accountStatus: 'Status konta',
        replacementPerson: 'Osoba zastępująca',
        active: 'Aktywny',
        blocked: 'Zablokowany',
      },
      errors: {
        missingUnits: 'Nie znaleźiono jednostek dla tej firmy',
      },
    },
    resetAuthSuccess:
      'Wniosek użytkownika o reset weryfikacji dwuetapowej został zaakceptowany',
    resetAuthConfirm:
      'Czy na pewno chcesz zresetować weryfikację dwuetapową użytkownika {name}?',
    deleteSuccess: 'Użytkownik został usunięty',
    deleteConfirm: 'Czy na pewno chcesz usunąć użytkownika {name}?',
    blockConfirm: 'Czy na pewno chcesz zablokować użytkownika {name}?',
    activeConfirm: 'Czy na pewno chcesz aktywowac użytkownika {name}?',
    blockSuccess: 'Użytkownik {name} został zablokowany.',
    activeSuccess: 'Użytkownik {name} został aktywowany.',
    deletedAt: 'Pokaż usuniętych',
    showActive: 'Pokaż aktywnych',
    showAll: 'Pokaż wszystkich',
    showDeleted: 'Pokaż usuniętych',
    userIsDeleted: 'Użytkownik został usunięty',
    showUsers: 'Pokaż użytkowników',
  },
}
