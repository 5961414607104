import { errorsService } from '@/main'
import client from '@/services/client'
import { errorsEnum } from '@/types/ErrorTypes'
import { isAxiosError } from 'axios'
import type {
  IAsset,
  IAssetAdditionalFormData,
  IAssetCriticality,
  IAssetCriticalityForm,
  IAssetFormData,
  IAssetRisk,
} from '@/types/AssetsTypes'
import type { Nullable } from '@/types/Nullable'

class AssetsRepository {
  public async getAssets(): Promise<IAsset[]> {
    return await client
      .get(`assets/list`)
      .then((response) => {
        return response.data.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAssets,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getAssetRisks(id: number): Promise<IAssetRisk[]> {
    return await client
      .get(`assets/${id}/risk/list`)
      .then((response) => {
        return response.data.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAssets,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getAllAssetRisks(): Promise<IAssetRisk[]> {
    return await client
      .get(`assets/risk/list`)
      .then((response) => {
        return response.data.original.data ?? []
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAssets,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getNewAssetRisks(
    assetId: number,
    userId: number
  ): Promise<number> {
    return await client
      .post(`assets/${assetId}/risk?userId=${userId}`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetNewRisk,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getAssetRisk(id: number): Promise<Nullable<IAssetRisk>> {
    return await client
      .get(`assets/risk/${id}`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return null
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAssets,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getAsset(id: number): Promise<IAsset> {
    return await client
      .get(`assets/${id}`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAssets,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getLastAsset(userId: number): Promise<IAsset> {
    return await client
      .get(`assets?filterBy[ticket.user_id]=${userId}&filterBy[status_id]=1`)
      .then((response) => {
        return response.data[0] ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return null
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAssets,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async addNewAsset(userId: number): Promise<Nullable<number>> {
    return await client
      .post(`assets`, { userId: userId })
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return null
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAssets,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getAssetCriticality(id: number): Promise<IAssetCriticality> {
    return await client
      .get(`assets/criticality/${id}`)
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAssets,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async updateField(
    id: number,
    formAsset: IAssetFormData,
    formAdditionalData: IAssetAdditionalFormData
  ): Promise<IAsset> {
    return await client
      .patch(`assets/${id}`, {
        assetData: formAsset,
        assetAdditionalData: formAdditionalData,
      })
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.SaveAsset,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async updateFieldCriticality(
    id: number,
    form: IAssetCriticalityForm
  ): Promise<IAssetCriticality> {
    return await client
      .patch(`assets/${id}/criticality`, {
        assetCriticalityData: form,
      })
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.GetAssets,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async saveAsset(
    id: number,
    formAsset: IAssetFormData,
    formAdditionalData: IAssetAdditionalFormData
  ): Promise<IAsset> {
    return await client
      .put(`assets/${id}`, {
        assetData: formAsset,
        assetAdditionalData: formAdditionalData,
      })
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.SaveAsset,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async saveCriticality(
    id: number,
    form: IAssetCriticalityForm
  ): Promise<IAssetCriticality> {
    return await client
      .put(`assets/${id}/criticality`, {
        assetCriticalityData: form,
      })
      .then((response) => {
        return response.data ?? null
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return ''
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.SaveAssetCriticality,
            response: error.response,
            ignoreToast: true,
          })
        }
        throw error
      })
  }

  public async saveRisk(id: number): Promise<void> {
    return await client
      .put(`assets/risk/${id}`)
      .then(() => {
        return
      })
      .catch((error) => {
        if (isAxiosError(error)) {
          if (!error.response) return
          errorsService.setScopeErrorsFromResponse({
            scope: errorsEnum.SaveAssetRisk,
            response: error.response,
          })
        }
        throw error
      })
  }

  public async getAssetsByStatusId(status: number = 1): Promise<IAsset[]> {
    const filterBy: Record<string, number | null | string>[] = [
      {
        status_id: status,
      },
    ]

    const acc = [filterBy]
    const payload = new URLSearchParams()
    payload.append('filterBy', JSON.stringify(acc))

    try {
      const result = await client.get<IAsset[]>(`assets`, {
        params: payload,
      })
      return result.data
    } catch (error) {
      if (isAxiosError(error)) {
        if (!error.response) throw error

        errorsService.setScopeErrorsFromResponse({
          scope: errorsEnum.GetAssetsByUserId,
          response: error.response,
        })
      }

      throw error
    }
  }
}

export default new AssetsRepository()
