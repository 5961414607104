export default {
  consents: {
    consents: 'Zgody',
    newConsents: 'Nowa zgoda',
    editConsents: 'Edycja zgody',
    description: 'Opis',
    activeConsent: 'Czy zgoda jest aktywna?',
    name: 'Nazwa',
    yes: 'Tak',
    no: 'Nie',
    companies: 'Firmy',
    previewConsents: 'Podgląd zgody',
    username: 'Użytkownik',
    acceptDate: 'Data akceptacji',
    acceptedConsents: 'Użytkownicy którzy zaakceptowali zgodę',
    successUpdate: 'Zgoda została zaktualizowana',
    createSuccess: 'Zgoda została stworzona',
    activateConsent: 'Aktywuj zgodę',
    activeAttribute: 'Czy atrybut jest aktywny?',
    deactivateConsent: 'Dezaktywuj zgodę',
    editConsent: 'Edytuj zgodę',
    showConsent: 'Pokaż zgodę',
  },
}
